<template>
  <div class="p-col-12">
    <Panel header="Gerenciamento de Pessoas" style="height: 100%">
      <Fieldset legend="Filtrar por" class="mb-3">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <label for="nome">Nome</label>
            <InputText
              id="nome"
              v-model="lazyParams.name"
              autofocus
              placeholder="Digite o nome"
              @keydown.enter="loadLazyData"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="nome">Matricula</label>
            <InputText
              id="nome"
              v-model="lazyParams.credential"
              autofocus
              placeholder="Digite a matrícula"
              @keydown.enter="loadLazyData"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="nome">CPF</label>
            <InputMask
              id="nome"
              v-model="lazyParams.cpf"
              mask="999.999.999-99"
              autofocus
              placeholder="Digite o CPF"
              @keydown.enter="loadLazyData"
            />
          </div>

          <div class="field col-12 md:col-4">
            <label for="acting">Atuação:</label>
            <AutoComplete
              v-model="actingInput"
              :suggestions="sectors"
              autofocus
              @complete="searchSectors"
              @keydown.enter="loadLazyData"
              :virtualScrollerOptions="{ itemSize: 38 }"
              optionLabel="name"
              placeholder="Digite nome ou sigla do setor de Atuação"
              dropdown
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="capacity">Lotação:</label>
            <AutoComplete
              v-model="capacityInput"
              :suggestions="sectors"
              autofocus
              @complete="searchSectors"
              @keydown.enter="loadLazyData"
              :virtualScrollerOptions="{ itemSize: 38 }"
              optionLabel="name"
              placeholder="Digite nome ou sigla setor de Lotação"
              dropdown
            />
          </div>
        </div>

        <div class="flex justify-content-end flex-wrap">
          <Button
            label="Pesquisar"
            class="p-button-raised mr-2"
            icon="pi pi-search"
            @click="loadLazyData"
          />
          <Button
            label="Limpar"
            icon="pi pi-times"
            class="p-button-raised p-button-text"
            @click="cleanAll()"
          />
        </div>
      </Fieldset>

      <Toolbar class="mb-3">
        <template #start>
          <Button
            label="Novo"
            icon="pi pi-plus"
            class="mr-2"
            @click="showCreate"
          />
        </template>
      </Toolbar>

      <DataTable
        :value="persons"
        :lazy="true"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 30]"
        ref="dt"
        class="p-datatable-sm"
        dataKey="id"
        :totalRecords="totalRecords"
        :loading="loading"
        :rowClass="rowClass"
        @page="onPage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} pessoas."
      >
        <Toolbar class="mb-4">
          <template #start>
            <div
              style="width: 15px; height: 15px; background-color: #ffa500"
            ></div>
            <b style="margin-left: 5px; color: #ffa500">
              - Pessoas sem vínculo atribuído
            </b>
          </template>
        </Toolbar>
        <template #empty>
          <div class="text-center">Nenhum resultado encontrado...</div>
        </template>
        <Column field="key" header="N° Biometria" ref="key">
          <template #body="slotProps">
            <small v-if="slotProps.data.key">
              <b>
                {{ slotProps.data.key }}
              </b>
            </small>
            <small v-else>
              <i class="pi pi-key"></i>
            </small>
          </template>
        </Column>

        <Column field="name" header="Nome">
          <template #body="slotProps">
            {{ slotProps.data.name.toUpperCase() }}
          </template>
        </Column>
        <Column field="credential" header="Matricula">
          <template #body="slotProps">
            {{ slotProps.data.credential }}
          </template>
        </Column>
        <Column field="cpf" header="CPF">
          <template #body="slotProps">
            {{ slotProps.data.cpf }}
          </template>
        </Column>
        <Column field="contact" header="Contato">
          <template #body="slotProps">
            <div v-if="slotProps.data.contact">
              {{ slotProps.data.contact }}
            </div>
            <div v-else>
              <b>SEM CONTATO</b>
            </div>
          </template>
        </Column>
        <Column field="dtBirdate" header="Data de Nascimento">
          <template #body="slotProps"
            >{{ $DateTime.formatarDate(slotProps.data.dtBirdate) }}
          </template>
        </Column>
        <Column header="Ações">
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.key && this.$Token.checkAdmin()"
              icon="pi pi pi-qrcode"
              class="p-button-rounded p-button-danger mr-2"
              @click="showAcess(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA GERAR ACESSO'"
            />
            <Button
              icon="pi pi-list"
              class="p-button-rounded mr-2"
              @click="showBond(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA ACESSAR O VINCULO'"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="showUpdate(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EDITAR'"
            />
            <Button
              icon="pi pi-camera"
              class="p-button-rounded p-button-info mr-2"
              @click="showUpload(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA ENVIAR A FOTO'"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="showRemove(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EXCLUIR'"
            />
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>
  <dialog-form :personSelected="person" @findAll="loadLazyData" />
  <dialog-form-bond :personSelected="person" @findAll="loadLazyData" />
  <dialog-upload
    :objectSelected="person"
    :objectService="personService"
    :showImg="true"
    :keyFolder="'F-2eb9769e0-21ea-49ad-9d4c-ab18fd55573c'"
    :stateDialog="$store.state.views.persons"
    @findAll="findAll"
  />

  <ConfirmDialog></ConfirmDialog>
</template>

<script>
//Models
import Person from "../../models/person";

//Services
import PersonService from "../../service/persons/person_service";
import BondService from "../../service/bond/bond_service";
import SectorService from "../../service/sector/sector";
import CatracaService from "../../service/catraca/catraca_service";

//Components
import DialogForm from "./components/dialog-form.vue";
import DialogUpload from "../../components/upload/dialog-upload.vue";
import DialogFormBond from "./components/bond/dialog-bond.vue";

export default {
  components: {
    DialogForm,
    DialogUpload,
    DialogFormBond,
  },
  data() {
    return {
      personService: new PersonService(),
      bondService: new BondService(),
      sectorService: new SectorService(),
      catracaService: new CatracaService(),
      person: new Person(),
      bonds: null,
      sectors: [],
      personBonds: [],
      loading: false,
      actingInput: null,
      capacityInput: null,
      totalRecords: 0,
      persons: [],
      lazyParams: {},
      sectorParams: {},
      firebase: null,
    };
  },
  mounted() {
    this.firebase = this.$Firebase;
    this.loading = true;
    this.lazyParams = {
      first: 0,
      size: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      name: "",
      credential: "",
      cpf: "",
      occupation: null,
      office: null,
      acting: null,
      capacity: null,
      situation: null,
      categoryBond: null,
      localIds: null,
    };

    this.actingInput = "";
    this.capacityInput = "";
    this.loadLazyData();
  },
  methods: {
    showCreate() {
      this.person = new Person();
      this.$store.state.views.persons.dialogForm = true;
    },
    showUpdate(person) {
      this.person = { ...person };
      this.$store.state.views.persons.dialogForm = true;
    },
    showUpload(person) {
      this.person = person;
      this.$store.state.views.persons.dialogUpload = true;
    },
    showBond(person) {
      this.person = person;
      this.$store.state.views.bond.dialogFormBond = true;
    },
    showAcess(person) {
      const nameParts = person.name.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
      this.$confirm.require({
        message: "Deseja conceder o acesso à " + person.name + "?",
        header: "Conceder acesso?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.loading = true;
          this.catracaService
            .registerAcces(firstName, lastName, person.key.toString())
            .then((data) => {
              this.$msgSuccess(200, data);
              this.loading = false;
            })
            .catch((error) => {
              console.error(error); // Caso ocorra um erro
            })
            .finally(() => {
              this.loading = false; // Será executado sempre, independentemente do resultado
            });
        },
      });
    },
    showRemove(person) {
      this.person = person;
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          if (this.person.image) {
            this.deleteImage();
          } else {
            this.deleteData();
          }
        },
      });
    },
    deleteImage() {
      this.loading = true;
      this.firebase
        .onDelete(`person/${this.person.id}`)
        .then(() => {
          this.deleteData();
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Alerta de Erro.",
            detail: err,
            life: 6000,
          });
        });
    },
    deleteData() {
      this.personService
        .delete(this.person.id)
        .then((data) => {
          this.$msgSuccess(data);
          this.loadLazyData();
        })
        .catch((error) => {
          this.$msgErro(error);
          this.loading = false;
          //console.error(error);
        });
    },
    loadLazyData() {
      this.loading = true;

      // Obtém o valor de localIds do sessionStorage
      this.lazyParams.localIds = parseInt(sessionStorage.getItem("local"));

      // Define os parâmetros de atuação e lotação, caso existam
      this.lazyParams.acting = this.actingInput?.name || "";
      this.lazyParams.capacity = this.capacityInput?.name || "";

      // Cria um novo objeto para armazenar apenas os parâmetros válidos
      const validParams = {
        ...this.lazyParams,
      };

      // Remove propriedades com valores nulos ou vazios e não as envia via URL
      Object.keys(validParams).forEach((key) => {
        if (validParams[key] === null || validParams[key] === "") {
          delete validParams[key];
        }
      });

      // Verifica se os parâmetros mudaram, exceto o 'page'
      const previousParams = { ...this.lazyParams }; // Copia os parâmetros anteriores

      // Compara os parâmetros excluindo o 'page'
      delete previousParams.page; // Remove 'page' para comparação

      const paramsChanged = Object.keys(previousParams).some(
        (key) => previousParams[key] !== validParams[key]
      );

      // Se os parâmetros mudaram, redefine 'page' para 0
      if (paramsChanged) {
        this.lazyParams.page = 0; // Reseta a página
      }

      // Chama o serviço para buscar as pessoas com os parâmetros válidos
      this.personService.searchPerson(validParams).then((data) => {
        this.persons = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
        if (this.totalRecords === 0) {
          this.$toast.add({
            severity: "warn",
            summary: "Alerta!",
            detail: "Não foram encontrados registros.",
            life: 3000,
          });
        }
        this.checkBonds();
      });
    },
    onPage(event) {
      this.lazyParams.page = event.page;
      this.lazyParams.size = event.rows;
      this.loadLazyData();
    },
    searchSectors(event) {
      this.sectorParams = {
        name: event.query,
        size: 100,
        localIds: sessionStorage.getItem("local"),
      };

      this.sectorService.findAll(this.sectorParams).then((data) => {
        if (data.content.length === 0) {
          this.sectorParams = {
            acronym: event.query,
            size: 100,
            localIds: sessionStorage.getItem("local"),
          };
          this.sectorService.findAll(this.sectorParams).then((data2) => {
            this.sectors = data2.content;
          });
        } else {
          this.sectors = data.content;
        }
      });
    },
    cleanAll() {
      this.lazyParams = {
        first: 0,
        size: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
        credential: "",
        person: "",
        email: "",
        cpf: "",
        acting: "",
        capacity: "",
      };
      this.actingInput = null;
      this.capacityInput = null;
      this.loading = false;
      this.loadLazyData();
    },
    checkBonds() {
      // Limpa a lista de personBonds antes de preencher
      this.personBonds = [];

      // Itera sobre cada person na lista
      this.persons.forEach((person) => {
        this.bondService.existBondByPersonId(person.id).then((data) => {
          // Adiciona o person e o status de vínculo ao array personBonds
          this.personBonds.push({
            person: person,
            exists: data, // true ou false
          });
        });
      });
    },
    rowClass(person) {
      let personsWithoutId = this.personBonds.some(
        (bond) => bond.person.id === person.id && bond.exists === false
      );

      if (personsWithoutId) {
        return ["text-orange-500"];
      }
    },
  },
};
</script>
