import AuthService from "../auth/auth_service";
import axios from "axios";

export default class GenderService extends AuthService {
    registerAcces(firstName, lastName, key) {
        return axios({
            method: 'get',
            url: this.api_url + 'auth-catraca/' + firstName + '/' + lastName + '/' + key,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }
}