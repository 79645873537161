<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '640px' }"
    header="Formulário de Jornada de Trabalho"
    :modal="true"
    @hide="hideDialog"
  >
    <Fieldset legend="Jornada de Trabalho">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="entrance">Horário de Entrada</label>
          <InputText
            id="entrance"
            v-model="v$.workday.entrance.$model"
            type="time"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && v$.workday.entrance.$invalid }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.workday.entrance.$invalid"
            >Horário de Entrada é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="exit">Horário de Saída</label>
          <InputText
            id="exit"
            v-model="v$.workday.exit.$model"
            type="time"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && v$.workday.exit.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.workday.exit.$invalid"
            >Horário de Saída é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="breakTime">Intervalo</label>
          <InputText
            id="breakTime"
            v-model="v$.workday.breakTime.$model"
            type="time"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && v$.workday.breakTime.$invalid }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.workday.breakTime.$invalid"
            >Intervalo é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="ch">Carga Horária (Diária)</label>
          <InputNumber
            inputId="ch"
            v-model="v$.workday.ch.$model"
            mode="decimal"
            :min="4"
            :max="12"
            showButtons
            :class="{ 'p-invalid': submitted && v$.workday.ch.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.workday.ch.$invalid"
            >Carga Horária é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="dtStart">Início</label>
          <InputText
            id="dtStart"
            v-model="v$.workday.dtStart.$model"
            type="date"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && v$.workday.dtStart.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.workday.dtStart.$invalid"
            >Inicio é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="dtEnd">Término</label>
          <InputText
            id="dtEnd"
            v-model="workday.dtEnd"
            type="date"
            placeholder="Digite o n° do vínculo"
            :disabled="workday.id === null"
          />
        </div>
        <div class="field col-12 md:col-12">
          <label for="description">Descrição</label>
          <InputText
            id="description"
            v-model="workday.description"
            placeholder="Digite a descrição"
          />
        </div>
      </div>
    </Fieldset>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.workday.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog()"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Bond from "@/models/bond";
import Workday from "@/models/workday";

//Services
import WorkdayService from "@/service/workday/workday_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["bondSelected", "workdaySelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      workday: new Workday(),
      bond: new Bond(),
      submitted: false,
      workdayService: new WorkdayService(),
    };
  },
  mounted() {},
  created() {},
  validations() {
    return {
      workday: new Workday().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogFormWorkday;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogFormWorkday = value;
      },
    },
  },
  methods: {
    getData() {
      if (this.bondSelected) {
        this.workday.bond = this.bondSelected;
        this.workday = this.workdaySelected;
        this.workday.dtStart = this.$DateTime.formatarDateInput(
          this.workday.dtStart
        );
        this.workday.dtEnd = this.$DateTime.formatarDateInput(
          this.workday.dtEnd
        );
      }
    },
    send(isFormValid) {
      this.submitted = true;

      if (isFormValid) {
        if (this.workday.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.workday.breakTime = this.workday.breakTime + ":00";
      this.workday.entrance = this.workday.entrance + ":00";
      this.workday.exit = this.workday.exit + ":00";
      this.workdayService
        .create(this.workday)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.workdayService
        .update(this.workday)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.workday = new Workday();
      this.submitted = false;
      this.visibleDialog = false;
    },
  },
};
</script>
<style scoped></style>
