import axios from "axios";
import AuthService from "../auth/auth_service.js";

export default class WorkdayService extends AuthService {
  
  constructor() {
    super("work-day");
  }

  findByBondId(bondId) {
    return axios({
      method: 'get',
      url: this.api_url + 'work-day/' + bondId,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res);
  }

}
