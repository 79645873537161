<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '750px' }"
    :header="'HISTÓRICO DE AFASTAMENTO | ' + bondSelected.person.name"
    :modal="true"
    @hide="hideDialog"
  >
    <Toolbar>
      <template #start>
        <Button
          label="Novo"
          icon="pi pi-plus"
          class="p-button-success"
          @click="showCreate"
        />
      </template>

      <template #end> </template>
    </Toolbar>

    <DataTable :value="historicsTypeRemovals">
      <template #empty>
        <div class="p-text-center">Nenhum resultado encontrado...</div>
      </template>
      <Column field="typeRemoval" header="Tipo de Afastamento">
        <template #body="slotProps">{{
          slotProps.data.typeRemoval.initials
        }}</template>
      </Column>
      <Column field="dtInit" header="Início">
        <template #body="slotProps">{{
          $DateTime.formatarDate(slotProps.data.dtInit)
        }}</template>
      </Column>
      <Column field="dtEnd" header="Término">
        <template #body="slotProps">
          <div v-if="!slotProps.data.dtEnd">
            <b>SEM TÉRMINO</b>
          </div>
          <div v-else>
            {{ $DateTime.formatarDate(slotProps.data.dtEnd) }}
          </div>
        </template>
      </Column>
      <Column field="observations" header="Observação">
        <template #body="slotProps">{{ slotProps.data.observation }}</template>
      </Column>
      <Column field="color" header="Ações">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mr-2"
            @click="showUpdate(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EDITAR'"
          />
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Fechar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
  <dialog-form-historic-removal
    :bondSelected="bondSelected"
    :historicRemovalSelected="historicRemoval"
    @findAll="getData()"
  />
</template>

<script>
//Models
import Bond from "@/models/bond";
import HistoricRemoval from "@/models/historic_removal";

//Services
import BondService from "@/service/bond/bond_service";
import HistoricRemovalService from "@/service/historic_removal/historic_removal_service";
import TypeRemovalService from "@/service/type_removal/type_removal_service";
//Components
import DialogFormHistoricRemoval from "../hystoric_removal/dialog-form-historic-removal.vue"; /*"../components/dialog-form-historic-removal.vue";*/

export default {
  props: ["bondSelected"],
  components: {
    DialogFormHistoricRemoval,
  },
  data() {
    return {
      bondService: new BondService(),
      bond: new Bond(),
      historicRemoval: new HistoricRemoval(),
      historicRemovalService: new HistoricRemovalService(),
      typeRemovalService: new TypeRemovalService(),
      historicsTypeRemovals: [],
      selectedBond: null,
      selectAll: false,
    };
  },
  mounted() {},
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogHistoricRemoval;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogHistoricRemoval = value;
      },
    },
  },
  methods: {
    getData() {
      if (this.bondSelected) {
        this.bond = this.bondSelected;
        this.historicRemovalService
          .findByBondId(this.bond.id)
          .then((response) => {
            this.historicsTypeRemovals = response.data;
          });
      }
    },
    showCreate() {
      this.historicRemoval = new HistoricRemoval();
      this.$store.state.views.bond.dialogFormHistoricRemoval = true;
    },
    showUpdate(historicRemoval) {
      this.historicRemoval = { ...historicRemoval };
      this.$store.state.views.bond.dialogFormHistoricRemoval = true;
    },
    hideDialog() {
      this.historicRemoval = new HistoricRemoval();
      this.submitted = false;
      this.visibleDialog = false;
    },
  },
};
</script>

<style></style>
