<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '1080px' }"
    header="Formulário de Vínculo"
    :modal="true"
    @hide="hideDialog"
  >
    <Fieldset legend="Informações do Servidor">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-5">
          <label for="person">Servidor:</label>
          <InputText id="person" v-model="bond.person.name" disabled />
        </div>
        <div class="field col-12 md:col-5">
          <label for="regiment">Regime:</label>
          <Dropdown
            v-model="v$.bond.regiment.$model"
            :options="regiments"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar Setor de Atuação"
            id="regiment"
            :class="{ 'p-invalid': submitted && v$.bond.regiment.id.$invalid }"
            @change="applyFilteringRules()"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.regiment.id.$invalid"
            >Regime é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-2">
          <label for="numberBond">N° do Vinculo:</label>
          <InputText
            id="numberBond"
            v-model="v$.bond.numberBond.$model"
            maxlength="255"
            placeholder="N° do vínculo"
            :class="{ 'p-invalid': submitted && v$.bond.numberBond.$invalid }"
            :disabled="isTemporaryEmployee"
          />
          <small class="p-error" v-if="submitted && v$.bond.numberBond.$invalid"
            >N° do Vínculo é obrigatório.</small
          >
        </div>
        <div v-if="isColaboratorOrTraine()" class="field col-12 md:col-4">
          <label for="dtExercise">Data de Exercício:</label>
          <InputText
            id="dtExercise"
            v-model="bond.dtExercise"
            maxlength="255"
            type="date"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && !bond.dtExercise }"
            :disabled="isTemporaryEmployee"
          />
          <small class="p-error" v-if="submitted && !bond.dtExercise"
            >Data de Exercício é obrigatória.</small
          >
        </div>
        <div v-if="isColaboratorOrTraine()" class="field col-12 md:col-4">
          <label for="dtInit">Data da Posse:</label>
          <InputText
            id="dtInit"
            v-model="bond.dtInit"
            maxlength="255"
            type="date"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && !bond.dtInit }"
            :disabled="isTemporaryEmployee"
          />
          <small class="p-error" v-if="submitted && !bond.dtInit"
            >Data da Posse é obrigatória.</small
          >
        </div>
        <div v-if="isColaboratorOrTraine()" class="field col-12 md:col-4">
          <label for="dtEnd">Data de Término:</label>
          <InputText
            id="dtEnd"
            v-model="bond.dtEnd"
            maxlength="255"
            type="date"
            placeholder="Digite o n° do vínculo"
            :disabled="isTemporaryEmployee"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label for="acting">Atuação:</label>
          <AutoComplete
            v-model="actingInput"
            :suggestions="sectors"
            @complete="searchSectors"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            placeholder="Digite nome ou sigla do setor"
            :class="{ 'p-invalid': submitted && v$.bond.acting.id.$invalid }"
            dropdown
          />
          <small class="p-error" v-if="submitted && v$.bond.acting.id.$invalid"
            >Setor de Atuação é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label for="capacity">Lotação:</label>
          <AutoComplete
            v-model="capacityInput"
            :suggestions="sectors"
            @complete="searchSectors"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            placeholder="Digite nome ou sigla setor"
            :class="{ 'p-invalid': submitted && v$.bond.capacity.id.$invalid }"
            dropdown
          />

          <small
            class="p-error"
            v-if="submitted && v$.bond.capacity.id.$invalid"
            >Setor de Lotação é obrigatório.</small
          >
        </div>
      </div>
    </Fieldset>
    <br />
    <Fieldset legend="Informações do Vínculo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="situation">Situação:</label>
          <Dropdown
            v-model="v$.bond.situation.id.$model"
            :options="situations"
            @click="applyFilteringRules()"
            filter="true"
            optionLabel="description"
            optionValue="id"
            placeholder="Selecionar a Situação"
            id="situation"
            :class="{ 'p-invalid': submitted && v$.bond.situation.id.$invalid }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.situation.id.$invalid"
            >Situação é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="typeBond">Tipo do Vinculo:</label>
          <Dropdown
            v-model="v$.bond.typeBond.id.$model"
            :options="typeBonds"
            filter="true"
            optionLabel="description"
            optionValue="id"
            placeholder="Selecionar a função"
            id="typeBond"
            :class="{ 'p-invalid': submitted && v$.bond.typeBond.id.$invalid }"
            :disabled="isTemporaryEmployee"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.typeBond.id.$invalid"
            >Tipo do Vinculo é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="categoryBond">Categoria de Vinculo:</label>
          <Dropdown
            v-model="v$.bond.categoryBond.id.$model"
            :options="categoryBonds"
            filter="true"
            optionLabel="description"
            optionValue="id"
            placeholder="Selecionar a função"
            id="categoryBond"
            :class="{
              'p-invalid': submitted && v$.bond.categoryBond.id.$invalid,
            }"
            :disabled="isTemporaryEmployee"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.categoryBond.id.$invalid"
            >Categoria do Vinculo é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="office">Cargo:</label>
          <Dropdown
            v-model="bond.office.id"
            :options="offices"
            filter="true"
            optionLabel="description"
            optionValue="id"
            placeholder="Selecionar o Cargo"
            id="office"
            @change="isSectorValid"
            :class="{
              'p-invalid': (submitted && !bond.office.id) || isSameSector,
            }"
            :disabled="isTemporaryEmployee"
          />
          <small class="p-error" v-if="submitted && !bond.office.id"
            >Cargo é obrigatório</small
          >
          <small class="p-error" v-if="isSameSector"
            >Campos de Cargo e Função não podem ser iguais.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="occupation">Função:</label>
          <Dropdown
            v-model="bond.occupation.id"
            :options="occupations"
            filter="true"
            optionLabel="description"
            optionValue="id"
            placeholder="Selecionar a função"
            id="occupation"
            @change="isSectorValid"
            :class="{
              'p-invalid': (submitted && !bond.occupation.id) || isSameSector,
            }"
            :disabled="isTemporaryEmployee"
          />
          <small class="p-error" v-if="submitted && !bond.occupation.id"
            >Função é obrigatório</small
          >
          <small class="p-error" v-if="isSameSector"
            >Campos de Cargo e Função não podem ser iguais.</small
          >
        </div>
      </div>
    </Fieldset>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.bond.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog()"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Bond from "../../../../models/bond";
import Person from "../../../../models/person";

//Services
import BondService from "@/service/bond/bond_service";
import SectorService from "@/service/sector/sector";
import OfficeService from "@/service/office/office_service";
import SituationService from "@/service/situation/situation_service";
import OccupationService from "@/service/occupation/occupation_service";
import CategoryBondService from "@/service/category_bond/category_bond_service";
import RegimentService from "@/service/regiment/regiment_service";
import TypeBondService from "@/service/type_bond/type_bond_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["bondSelected", "personSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      bond: new Bond(),
      person: new Person(),
      submitted: false,
      bondService: new BondService(),
      sectorService: new SectorService(),
      officeService: new OfficeService(),
      situationService: new SituationService(),
      occupationService: new OccupationService(),
      categoryBondService: new CategoryBondService(),
      regimentService: new RegimentService(),
      typeBondService: new TypeBondService(),
      sectors: null,
      isSameSector: false,
      isTemporaryEmployee: false,
      sectorParams: {},
      lazyParams: {},
      filteredItems: null,
      actingInput: "",
      capacityInput: "",
      offices: [],
      situations: [],
      occupations: [],
      categoryBonds: [],
      regiments: [],
      typeBonds: [],
      typeRemovals: [],
    };
  },
  validations() {
    return {
      bond: new Bond().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogForm = value;
      },
    },
  },
  watch: {
    // Observa mudanças no campo "v$.bond.regiment.$model"
    "v$.bond.regiment.$model": function (selectedOption) {
      // Verifica se selectedOption é igual ao bondSelected e se é funcionário temporário (id = 3 ou 4)
      if (
        selectedOption.id === this.bondSelected.regiment.id &&
        (selectedOption.id === 3 || selectedOption.id === 4)
      ) {
        this.isTemporaryEmployee = true;
        this.setAllRelevantFieldsForTemporaryEmployee(); // Configurações específicas para temporários

        // Caso selectedOption seja 3 ou 4 (funcionário temporário) independente do valor atual
      } else if (selectedOption.id === 3 || selectedOption.id === 4) {
        this.isTemporaryEmployee = true;
        this.setAllRelevantFieldsForTemporaryEmployee();

        // Verifica se selectedOption é igual ao bondSelected e NÃO é funcionário temporário
      } else if (
        selectedOption.id === this.bondSelected.regiment.id &&
        selectedOption.id !== 3 &&
        selectedOption.id !== 4
      ) {
        this.isTemporaryEmployee = false; // Configura como não temporário

        // Qualquer outro caso: reset das configurações temporárias
      } else {
        this.isTemporaryEmployee = false;
        this.setAllRelevantFieldsForTemporaryEmployee();
      }
    },
  },

  methods: {
    getData() {
      if (this.bondSelected.id) {
        this.bond = { ...this.bondSelected };
        this.actingInput =
          this.bond.acting.id == null ? this.actingInput : this.bond.acting;
        this.capacityInput =
          this.bond.capacity.id == null
            ? this.capacityInput
            : this.bond.capacity;
      }
      this.bond.person = this.personSelected;
      this.getAllServices();
    },
    isSectorValid() {
      if (this.bond.office.id != null && this.bond.occupation.id != null) {
        const office = this.bond.office.id;
        const occupation = this.bond.occupation.id;
        if (office === occupation) {
          this.isSameSector = true;
        } else {
          this.isSameSector = false;
        }
      }
    },
    send(isFormValid) {
      this.submitted = true;
      this.bond.acting = this.actingInput;
      this.bond.capacity = this.capacityInput;
      if (isFormValid && !this.isSameSector) {
        if (this.bond.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.bondService
        .create(this.bond)
        .then((data) => {
          this.$msgSuccess(data);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.bondService
        .update(this.bond)
        .then((data) => {
          this.$msgSuccess(data);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.bond = new Bond();
      this.submitted = false;
      this.actingInput = "";
      this.capacityInput = "";
      this.visibleDialog = false;
      this.isTemporaryEmployee = false;
      this.$emit("findAll");
    },
    searchSectors(event) {
      this.sectorParams = {
        name: event.query,
        size: 100,
        localIds: sessionStorage.getItem("local"),
      };
      this.sectorService.findAll(this.sectorParams).then((data) => {
        if (data.content.length === 0) {
          this.sectorParams = {
            acronym: event.query,
            size: 100,
            localIds: sessionStorage.getItem("local"),
          };
          this.sectorService.findAll(this.sectorParams).then((data2) => {
            this.sectors = data2.content;
          });
        } else {
          this.sectors = data.content;
        }
      });
    },

    //METODO PARA SETAR OS IDS CORRESPONDENTES AOS PERFIS DE COLABORADOR E ESTAGIÁRIO
    setAllRelevantFieldsForTemporaryEmployee() {
      if (this.bond.regiment.id === 3) {
        this.bond.numberBond = 0;
        this.bond.dtExercise = null;
        this.bond.dtInit = null;
        this.bond.dtEnd = null;
        this.bond.typeBond.id = 10001; // Colaborador
        this.bond.categoryBond.id = 10002; // Colaborador
        this.bond.office.id = 10000; // NÃO SE APLICA
        this.bond.occupation.id = 10000; // NÃO SE APLICA
      } else if (this.bond.regiment.id === 4) {
        this.bond.numberBond = 0;
        this.bond.dtExercise = null;
        this.bond.dtInit = null;
        this.bond.dtEnd = null;
        this.bond.typeBond.id = 10002; // Estagiário
        this.bond.categoryBond.id = 10001; // Estagiário
        this.bond.office.id = 10015; // CARGO NÃO SE APLICA
        this.bond.occupation.id = 10000; // FUNÇÃO NÃO SE APLICA
      } else {
        this.bond.situation.id = null;
        this.bond.numberBond = null;
        this.bond.typeBond.id = null;
        this.bond.categoryBond.id = null;
        this.bond.office.id = null;
        this.bond.occupation.id = null;
      }
    },
    getAllServices() {
      this.findAllOffices();
      this.findAllSituations();
      this.findAllOccupations();
      this.findAllCategoryBonds();
      this.findAllRegiments();
      this.findAllTypeBonds();
    },
    findAllSituations() {
      this.situationService.findAll().then((data) => {
        this.situations = data;
      });
    },
    findAllOccupations() {
      this.occupationService.findAll().then((data) => {
        this.occupations = data;
      });
    },
    findAllCategoryBonds() {
      this.categoryBondService.findAll().then((data) => {
        this.categoryBonds = data;
      });
    },
    findAllRegiments() {
      this.regimentService.findAll().then((data) => {
        this.regiments = data;
      });
    },
    findAllTypeBonds() {
      {
        this.typeBondService.findAll().then((data) => {
          this.typeBonds = data;
        });
      }
    },
    findAllOffices() {
      this.officeService.findAll().then((data) => {
        this.offices = data;
      });
    },
    isColaboratorOrTraine() {
      return this.bond.regiment.id !== 3 && this.bond.regiment.id !== 4;
    },
    applyFilteringRules() {
      // Obtenha o regiment.id (você pode ter que ajustar dependendo de onde o regiment.id está disponível)
      const regimentId = this.bond.regiment.id;

      const filterRules = {
        // Regras específicas para IDs individuais
        //EX: 4: (situations) => situations.filter(situation => [1, 2].includes(situation.id)),

        // Regras para múltiplos IDs
        traineeAndColaborator: (situations) =>
          situations.filter((situation) => [1, 2, 10].includes(situation.id)),
      };
      // Aplica a regra se existir para o 'regiment.id', senão mantém as situações originais
      if (filterRules[regimentId]) {
        this.situations = filterRules[regimentId](this.situations);
      } else if ([3, 4].includes(regimentId)) {
        this.situations = filterRules.traineeAndColaborator(this.situations);
      } else {
        this.findAllSituations();
      }
    },
  },
};
</script>
