import axios from "axios";
import AuthService from "../auth/auth_service";

export default class HistoricRemovalService extends AuthService {
  constructor() {
    super("historic-removal")
  }

  findByBondId(bondId) {
    return axios({
      method: 'get',
      url: this.api_url + 'historic-removal/' + bondId,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res);
  }

}
